import React from 'react';
import {
  Button,
  Divider,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const InformationsDuCompteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  // Traite l'array de nationalités pour afficher une chaîne de caractères
  const traitement_nationalites = array_nat => {
    return array_nat.join(', ');
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                { marginBottom: 10 }
              ),
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={
                    !(dimensions.width >= Breakpoints.Desktop)
                      ? 18
                      : dimensions.width >= Breakpoints.BigScreen
                      ? 30
                      : 25
                  }
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Informations du compte'}
            </Text>
          </View>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Bouton modifier infos mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                bottom: 20,
                marginTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                position: 'absolute',
                width: '100%',
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                .props}
              color1={palettes.App['Flolend 1']}
              color2={palettes.App['Flolend 4']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                    .style,
                  {
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                    flex: [
                      { minWidth: Breakpoints.Laptop, value: null },
                      { minWidth: Breakpoints.Tablet, value: null },
                    ],
                    height: [
                      { minWidth: Breakpoints.Laptop, value: 60 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                      { minWidth: Breakpoints.Desktop, value: 65 },
                    ],
                    width: '100%',
                  }
                ),
                dimensions.width
              )}
            >
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    navigation.navigate('ModifierMesInfosScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                    {
                      borderColor: null,
                      borderWidth: 0,
                      fontSize: { minWidth: Breakpoints.Desktop, value: 22 },
                      marginBottom: 0,
                      marginTop: 0,
                    }
                  ),
                  dimensions.width
                )}
                title={'Modifier mes informations'}
              />
            </LinearGradient>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: [
              { minWidth: Breakpoints.BigScreen, value: '100%' },
              { minWidth: Breakpoints.Laptop, value: '100%' },
              { minWidth: Breakpoints.Mobile, value: '100%' },
            ],
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            marginBottom: { minWidth: Breakpoints.Laptop, value: '2%' },
            marginLeft: [
              { minWidth: Breakpoints.Tablet, value: '10%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            marginRight: [
              { minWidth: Breakpoints.Tablet, value: '10%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Vue générale */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '2%' },
              marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Vue informations */}
          <View>
            {/* En-tête */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 10, marginLeft: 20, marginRight: 20 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                  dimensions.width
                )}
              >
                {'Mes informations'}
              </Text>
            </View>
            {/* Tableau informations */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 20, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Fetch client */}
              <BaseXanoApi.FetchAuthGET>
                {({ loading, error, data, refetchAuth }) => {
                  const fetchClientData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Vue info 1 - civilite */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 1a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'entree_table_infos compte'
                                ].style,
                                {
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 20,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 24,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Civilité'}
                          </Text>
                        </View>
                        {/* Vue 1b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchClientData?.Civilite}
                          </Text>
                        </View>
                      </View>
                      <Divider
                        {...GlobalStyles.DividerStyles(theme)[
                          'div infos compte'
                        ].props}
                        color={palettes.App.gris_fonce_flolend}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DividerStyles(theme)['div infos compte']
                            .style,
                          dimensions.width
                        )}
                      />
                      {/* Vue info 2 - prenom */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 2a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Prénom'}
                          </Text>
                        </View>
                        {/* Vue 2b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchClientData?.Prenom}
                          </Text>
                        </View>
                      </View>
                      {/* Divider 2 */}
                      <Divider
                        color={theme.colors.border.brand}
                        {...GlobalStyles.DividerStyles(theme)[
                          'div infos compte'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DividerStyles(theme)['div infos compte']
                            .style,
                          dimensions.width
                        )}
                      />
                      {/* Vue info 3 - nom */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 3a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Nom'}
                          </Text>
                        </View>
                        {/* Vue 3b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchClientData?.Nom}
                          </Text>
                        </View>
                      </View>
                      {/* Divider 3 */}
                      <Divider
                        color={theme.colors.border.brand}
                        {...GlobalStyles.DividerStyles(theme)[
                          'div infos compte'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DividerStyles(theme)['div infos compte']
                            .style,
                          dimensions.width
                        )}
                      />
                      {/* Vue info 4 - tel */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 4a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Numéro de téléphone'}
                          </Text>
                        </View>
                        {/* Vue 4b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center', width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchClientData?.telephone}
                          </Text>
                        </View>
                      </View>
                      {/* Divider 7 */}
                      <Divider
                        color={theme.colors.border.brand}
                        {...GlobalStyles.DividerStyles(theme)[
                          'div infos compte'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DividerStyles(theme)['div infos compte']
                            .style,
                          dimensions.width
                        )}
                      />
                      {/* Vue info 5 - adresse */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 4a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Adresse fiscale'}
                          </Text>
                        </View>
                        {/* Vue 4b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchClientData?.Adresse}
                          </Text>
                        </View>
                      </View>
                      {/* Divider 4 */}
                      <Divider
                        color={theme.colors.border.brand}
                        {...GlobalStyles.DividerStyles(theme)[
                          'div infos compte'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DividerStyles(theme)['div infos compte']
                            .style,
                          dimensions.width
                        )}
                      />
                      {/* Vue info 6 - birthdate */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 5a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Date de naissance'}
                          </Text>
                        </View>
                        {/* Vue 5b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {ConvertionDate(fetchClientData?.Date_de_naissance)}
                          </Text>
                        </View>
                      </View>
                      {/* Divider 5 */}
                      <Divider
                        color={theme.colors.border.brand}
                        {...GlobalStyles.DividerStyles(theme)[
                          'div infos compte'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DividerStyles(theme)['div infos compte']
                            .style,
                          dimensions.width
                        )}
                      />
                      {/* Vue info 7 - dptm naiss */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 6a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Département de naissance'}
                          </Text>
                        </View>
                        {/* Vue 6b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center', width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchClientData?.Departement_naissance}
                          </Text>
                        </View>
                      </View>
                      {/* Divider 6 */}
                      <Divider
                        color={theme.colors.border.brand}
                        {...GlobalStyles.DividerStyles(theme)[
                          'div infos compte'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DividerStyles(theme)['div infos compte']
                            .style,
                          dimensions.width
                        )}
                      />
                      {/* Vue info 8 - com naiss */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 7a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Commune de naissance'}
                          </Text>
                        </View>
                        {/* Vue 7b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center', width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchClientData?.Commune_naissance}
                          </Text>
                        </View>
                      </View>
                      {/* Divider 8 */}
                      <Divider
                        color={theme.colors.border.brand}
                        {...GlobalStyles.DividerStyles(theme)[
                          'div infos compte'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DividerStyles(theme)['div infos compte']
                            .style,
                          dimensions.width
                        )}
                      />
                      {/* Vue info 9 - nationalites */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 7a */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Nationalités'}
                          </Text>
                        </View>
                        {/* Vue 7b */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center', width: '50%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'entree_table_infos compte'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'entree_table_infos compte'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {traitement_nationalites(
                              fetchClientData?.Nationalites
                            )}
                          </Text>
                        </View>
                      </View>
                    </>
                  );
                }}
              </BaseXanoApi.FetchAuthGET>
            </View>
            {/* Bouton modifier infos laptop */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: [
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                      ],
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 20,
                    },
                    dimensions.width
                  )}
                >
                  <LinearGradient
                    endX={100}
                    endY={100}
                    startX={0}
                    startY={0}
                    {...GlobalStyles.LinearGradientStyles(theme)[
                      'gradient_bouton'
                    ].props}
                    color1={palettes.App['Flolend 1']}
                    color2={palettes.App['Flolend 4']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinearGradientStyles(theme)[
                          'gradient_bouton'
                        ].style,
                        {
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 15,
                          },
                          flex: [
                            { minWidth: Breakpoints.Laptop, value: null },
                            { minWidth: Breakpoints.Tablet, value: null },
                          ],
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 60 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                            { minWidth: Breakpoints.Desktop, value: 65 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          navigation.navigate('ModifierMesInfosScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                            .style,
                          {
                            borderColor: null,
                            borderWidth: 0,
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 22,
                            },
                            marginBottom: 0,
                            marginTop: 0,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Modifier mes informations'}
                    />
                  </LinearGradient>
                </View>
              )}
            </>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(InformationsDuCompteScreen);
