import * as React from 'react';
import { Provider as ThemeProvider } from '@draftbit/ui';
import { useFonts } from 'expo-font';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import {
  ActivityIndicator,
  AppState,
  Platform,
  StatusBar,
  Text,
  TextInput,
  View,
} from 'react-native';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppNavigator from './AppNavigator';
import Fonts from './config/Fonts.js';
import { GlobalVariableProvider } from './config/GlobalVariableContext';
import cacheAssetsAsync from './config/cacheAssetsAsync';
import Draftbit from './themes/Draftbit';

SplashScreen.preventAutoHideAsync();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const queryClient = new QueryClient();

const App = () => {
  const [areAssetsCached, setAreAssetsCached] = React.useState(false);

  const [fontsLoaded] = useFonts({
    Inter_500Medium: Fonts.Inter_500Medium,
    Inter_700Bold: Fonts.Inter_700Bold,
    Montserrat_500Medium: Fonts.Montserrat_500Medium,
    Montserrat_700Bold: Fonts.Montserrat_700Bold,
    Montserrat_400Regular: Fonts.Montserrat_400Regular,
    Montserrat_600SemiBold: Fonts.Montserrat_600SemiBold,
    Montserrat_300Light: Fonts.Montserrat_300Light,
    Montserrat_400Regular_Italic: Fonts.Montserrat_400Regular_Italic,
    PTSans_400Regular: Fonts.PTSans_400Regular,
    PTSans_700Bold: Fonts.PTSans_700Bold,
    PTSans_700Bold_Italic: Fonts.PTSans_700Bold_Italic,
    PTSans_400Regular_Italic: Fonts.PTSans_400Regular_Italic,
    Rubik_700Bold: Fonts.Rubik_700Bold,
  });

  React.useEffect(() => {
    async function prepare() {
      try {
        await cacheAssetsAsync();
      } catch (e) {
        console.warn(e);
      } finally {
        setAreAssetsCached(true);
      }
    }

    prepare();
  }, []);

  const isReady = areAssetsCached && fontsLoaded;
  const onLayoutRootView = React.useCallback(async () => {
    if (isReady) {
      await SplashScreen.hideAsync();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      {Platform.OS === 'android' ? (
        <StatusBar
          barStyle={'dark-content'}
          hidden={true}
          backgroundColor={'#111218ff'}
        />
      ) : null}
      <SafeAreaProvider
        initialMetrics={initialWindowMetrics}
        onLayout={onLayoutRootView}
      >
        <GlobalVariableProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider
              themes={[Draftbit]}
              breakpoints={{}}
              initialThemeName={'Draftbit'}
            >
              <AppNavigator />
            </ThemeProvider>
          </QueryClientProvider>
        </GlobalVariableProvider>
      </SafeAreaProvider>
    </>
  );
};

export default App;
