import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  LinearProgress,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_souscription_3_4: 166 };

const Souscription4Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_chargement, setAffichage_chargement] = React.useState(false);
  const [bouton, setBouton] = React.useState(false);
  const [message_erreur, setMessage_erreur] = React.useState(false);
  const [select_CB, setSelect_CB] = React.useState(false);
  const [select_compte, setSelect_compte] = React.useState(false);
  // Convertit une somme en euros en une somme en centimes d'euros
  const conversion_centimes = montant_euros => {
    const centimes = montant_euros * 100;
    return centimes;
  };

  // Calcule la différence entre 2 paramètres et renvoie le maximum entre 0 et la différence calculée.
  const maxDifference = (param1, param2) => {
    // Calcul de la différence entre les deux paramètres
    let difference = param1 - param2;

    // Renvoie le maximum entre 0 et la différence calculée
    return Math.max(0, difference);
  };
  const baseXanoPaiement$tokenPaiementPOST =
    BaseXanoApi.usePaiement$tokenPaiementPOST();
  const baseXanoPaiementPATCH = BaseXanoApi.usePaiementPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const retour_api = (await BaseXanoApi.conditionsSousCbGET(Constants))
          ?.json;
        setGlobalVariableValue({
          key: 'max_sous_cb',
          value: retour_api?.max_cb_souscription_euros,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Fetch souscription */}
      <BaseXanoApi.FetchSousParIdGET
        id_souscription={
          props.route?.params?.id_souscription_3_4 ??
          defaultProps.id_souscription_3_4
        }
      >
        {({ loading, error, data, refetchSousParId }) => {
          const fetchSouscriptionData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <SimpleStyleScrollView
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              bounces={false}
              scrollEnabled={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  height: { minWidth: Breakpoints.BigScreen, value: '100%' },
                  justifyContent: [
                    { minWidth: Breakpoints.BigScreen, value: 'space-between' },
                    { minWidth: Breakpoints.Laptop, value: 'space-between' },
                  ],
                  paddingTop: [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: Constants['hauteur_navbar_laptop'],
                    },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: Constants['hauteur_navbar_desktop'],
                    },
                    {
                      minWidth: Breakpoints.BigScreen,
                      value: Constants['hauteur_navbar_big_screen'],
                    },
                  ],
                  width: { minWidth: Breakpoints.BigScreen, value: '100%' },
                },
                dimensions.width
              )}
            >
              {/* En-tête */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'column',
                    marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginLeft: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      marginRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      marginTop: 10,
                      paddingLeft: [
                        { minWidth: Breakpoints.Laptop, value: 0 },
                        { minWidth: Breakpoints.Mobile, value: 16 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '80%' },
                          { minWidth: Breakpoints.Laptop, value: '100%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre_pipe_invest']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['titre_pipe_invest']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'Investir dans '}
                      {fetchSouscriptionData?.projets?.Name}
                    </Text>
                  </View>
                  {/* View 2 */}
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '20%' },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('BottomTabNavigator', {
                                screen: 'EspacePersonnelScreen',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <Icon
                            color={palettes.App.gris_fonce_flolend}
                            name={'Ionicons/exit-outline'}
                            size={18}
                          />
                        </Touchable>
                      </View>
                    )}
                  </>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginLeft: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      marginRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      paddingLeft: [
                        { minWidth: Breakpoints.Mobile, value: 16 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      paddingRight: [
                        { minWidth: Breakpoints.Mobile, value: 16 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                        .style,
                      dimensions.width
                    )}
                  >
                    {'Etape 4/4 : Paiement de la souscription'}
                  </Text>
                </View>
                {/* Bandeau progression */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App.gris_encart_int_flolend,
                      height: 50,
                      justifyContent: 'space-evenly',
                      marginTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '90%' },
                          { minWidth: Breakpoints.Laptop, value: '80%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          width: '25%',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'frise_pipe_invest_unselected'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].style,
                          dimensions.width
                        )}
                      >
                        {'Montant de la souscription'}
                      </Text>
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '25%' },
                        dimensions.width
                      )}
                    >
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'frise_pipe_invest_unselected'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].style,
                          dimensions.width
                        )}
                      >
                        {'Revue de la documentation'}
                      </Text>
                    </View>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '25%' },
                        dimensions.width
                      )}
                    >
                      {/* Text 3 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'frise_pipe_invest_unselected'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].style,
                          dimensions.width
                        )}
                      >
                        {'Signature'}
                      </Text>
                    </View>
                    {/* View 4 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '25%' },
                        dimensions.width
                      )}
                    >
                      {/* Text 4 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['frise_pipe_invest']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['frise_pipe_invest']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Paiement'}
                      </Text>
                    </View>
                  </View>
                  <LinearProgress
                    animationDuration={500}
                    color={theme.colors.branding.primary}
                    indeterminate={false}
                    isAnimated={true}
                    lineCap={'round'}
                    showTrack={true}
                    thickness={10}
                    trackColor={theme.colors.border.brand}
                    trackLineCap={'round'}
                    {...GlobalStyles.LinearProgressStyles(theme)[
                      'barre_pipe_invest'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.LinearProgressStyles(theme)[
                        'barre_pipe_invest'
                      ].style,
                      dimensions.width
                    )}
                    value={95}
                  />
                </View>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    alignSelf: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.gris_encart_int_flolend,
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '10%' },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '10%' },
                    ],
                    marginTop: { minWidth: Breakpoints.Laptop, value: '3%' },
                    padding: { minWidth: Breakpoints.Laptop, value: 30 },
                  },
                  dimensions.width
                )}
              >
                <View>
                  {/* Principe */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 10 },
                          { minWidth: Breakpoints.Laptop, value: 0 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* Heading */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['explications'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['explications'].style,
                        dimensions.width
                      )}
                    >
                      {
                        "Votre souscription est validée. Il ne vous reste plus qu'à la régler !"
                      }
                    </Text>
                  </View>
                  {/* Modalités de paiement */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: '2%' },
                      dimensions.width
                    )}
                  >
                    {/* Section Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Heading */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['titre1_generique']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['titre1_generique']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Modalités de paiement'}
                      </Text>
                    </View>
                    {/* Content */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderColor: palettes.App['Flolend 1'],
                          borderRadius: 12,
                          borderWidth: 1,
                          paddingBottom: 20,
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['explications']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['explications'].style,
                          dimensions.width
                        )}
                      >
                        {
                          "Vous disposez de 5 jours pour régler le montant de votre souscription à partir de la signature du bulletin de souscription. Passé ce délai, votre souscription pourra être annulée.\n\nLes règlements par carte bancaire sont disponibles pour des versements jusqu'à "
                        }
                        {format_nombre(Constants['max_sous_cb'], 0)}
                        {' €.'}
                      </Text>
                    </View>
                  </View>
                  {/* Sélection du mode de paiement */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        marginTop: 15,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Section Header 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Laptop,
                            value: 'flex-start',
                          },
                          justifyContent: 'space-between',
                          marginBottom: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Heading */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['titre1_generique']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['titre1_generique']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Sélection du mode de paiement'}
                      </Text>
                    </View>
                    {/* Touchable mon compte */}
                    <Touchable
                      onPress={() => {
                        try {
                          setSelect_compte(true);
                          setSelect_CB(false);
                          setBouton(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          width: { minWidth: Breakpoints.Laptop, value: '75%' },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Vue mon compte */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: palettes.App.Icon_Dark_Gray,
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: select_compte
                                  ? palettes.App['Flolend 4']
                                  : palettes.App.Icon_Dark_Gray,
                              },
                            ],
                            borderRadius: 12,
                            borderWidth: 1,
                            paddingBottom: '1%',
                            paddingLeft: '2%',
                            paddingRight: '2%',
                            paddingTop: '1%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '75%' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre_select_option_sous'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'titre_select_option_sous'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Mon compte Flolend'}
                            </Text>

                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'montants_options_souscription'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'montants_options_souscription'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Montant de la souscription : '}
                              {format_nombre(
                                fetchSouscriptionData?.montant_souscrit_euros,
                                2
                              )}
                              {' €\nSolde de mon compte : '}
                              {format_nombre(
                                conversion_solde_wallet(
                                  fetchSouscriptionData
                                    ?.client_de_la_souscription
                                    ?.solde_wallet_client
                                ),
                                2
                              )}
                              {' €\n--------\nMontant restant à verser : '}
                              {format_nombre(
                                maxDifference(
                                  fetchSouscriptionData?.montant_souscrit_euros,
                                  conversion_solde_wallet(
                                    fetchSouscriptionData
                                      ?.client_de_la_souscription
                                      ?.solde_wallet_client
                                  )
                                ),
                                2
                              )}
                              {' €'}
                            </Text>
                          </View>
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', width: '25%' },
                              dimensions.width
                            )}
                          >
                            {/* Icon 2 */}
                            <Icon
                              color={palettes.App.gris_fonce_flolend}
                              name={
                                'MaterialCommunityIcons/account-arrow-right'
                              }
                              size={
                                (!(dimensions.width >= Breakpoints.Laptop)
                                  ? 25
                                  : !(dimensions.width >= Breakpoints.Desktop)
                                  ? 35
                                  : dimensions.width >= Breakpoints.BigScreen
                                  ? 45
                                  : 40) ?? 40
                              }
                            />
                          </View>
                        </View>
                      </View>
                    </Touchable>
                    <>
                      {!(
                        fetchSouscriptionData?.montant_souscrit_euros <=
                        Constants['max_sous_cb']
                      ) ? null : (
                        <Spacer bottom={8} left={8} right={8} top={8} />
                      )}
                    </>
                    {/* Touchable CB */}
                    <>
                      {!(
                        fetchSouscriptionData?.montant_souscrit_euros <=
                        Constants['max_sous_cb']
                      ) ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              setSelect_compte(false);
                              setSelect_CB(true);
                              setBouton(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '75%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Vue CB */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: palettes.App.Icon_Dark_Gray,
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: select_CB
                                      ? palettes.App['Flolend 4']
                                      : palettes.App.gris_fonce_flolend,
                                  },
                                ],
                                borderRadius: 12,
                                borderWidth: 1,
                                paddingBottom: '1%',
                                paddingLeft: '2%',
                                paddingRight: '2%',
                                paddingTop: '1%',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '75%' },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'titre_select_option_sous'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_select_option_sous'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Carte bancaire'}
                                </Text>

                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'montants_options_souscription'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'montants_options_souscription'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Paiements limités à '}
                                  {format_nombre(Constants['max_sous_cb'], 0)}
                                  {' € max.'}
                                </Text>
                              </View>
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'center', width: '25%' },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={palettes.App.gris_fonce_flolend}
                                  name={'AntDesign/creditcard'}
                                  size={
                                    (!(dimensions.width >= Breakpoints.Laptop)
                                      ? 25
                                      : !(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                      ? 35
                                      : dimensions.width >=
                                        Breakpoints.BigScreen
                                      ? 45
                                      : 40) ?? 40
                                  }
                                />
                              </View>
                            </View>
                          </View>
                        </Touchable>
                      )}
                    </>
                  </View>
                  {/* Chargement en cours 2 */}
                  <>
                    {!affichage_chargement ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: 10,
                            marginTop: '5%',
                            paddingLeft: 20,
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          {...GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].props}
                          color={palettes.App['Flolend 4']}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].style,
                            dimensions.width
                          )}
                        />
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['corps_contenu']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['corps_contenu']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Chargement en cours, veuillez patienter.'}
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {affichage_chargement ? null : (
                      <LinearGradient
                        endX={100}
                        endY={100}
                        startX={0}
                        startY={0}
                        {...GlobalStyles.LinearGradientStyles(theme)[
                          'gradient_bouton'
                        ].props}
                        color1={
                          (bouton
                            ? palettes.App['Flolend 2']
                            : palettes.App.gris_fonce_flolend) ??
                          palettes.App['Flolend 2']
                        }
                        color2={
                          (bouton
                            ? palettes.App['Flolend 4']
                            : palettes.App.gris_fonce_flolend) ??
                          palettes.App['Flolend 4']
                        }
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinearGradientStyles(theme)[
                              'gradient_bouton'
                            ].style,
                            {
                              alignSelf: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 15,
                              },
                              flex: [
                                { minWidth: Breakpoints.Laptop, value: null },
                                { minWidth: Breakpoints.Mobile, value: null },
                              ],
                              height: [
                                { minWidth: Breakpoints.Laptop, value: 60 },
                                { minWidth: Breakpoints.Mobile, value: null },
                                { minWidth: Breakpoints.Desktop, value: 65 },
                                { minWidth: Breakpoints.BigScreen, value: 70 },
                              ],
                              marginBottom: [
                                { minWidth: Breakpoints.Laptop, value: 0 },
                                { minWidth: Breakpoints.Mobile, value: '2%' },
                              ],
                              marginTop: [
                                { minWidth: Breakpoints.Laptop, value: '2%' },
                                { minWidth: Breakpoints.Mobile, value: '5%' },
                              ],
                              overflow: {
                                minWidth: Breakpoints.Laptop,
                                value: 'hidden',
                              },
                              width: [
                                { minWidth: Breakpoints.Laptop, value: '50%' },
                                { minWidth: Breakpoints.Tablet, value: '50%' },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Bouton investir actif */}
                        <Button
                          accessible={true}
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                setMessage_erreur(false);
                                if (!bouton) {
                                  return;
                                }
                                setAffichage_chargement(true);
                                if (select_CB) {
                                  const retour_api = (
                                    await baseXanoPaiement$tokenPaiementPOST.mutateAsync(
                                      {
                                        montant: conversion_centimes(
                                          fetchSouscriptionData?.montant_souscrit_euros
                                        ),
                                      }
                                    )
                                  )?.json;
                                  if (retour_api?.success) {
                                    if (navigation.canGoBack()) {
                                      navigation.popToTop();
                                    }
                                    navigation.replace(
                                      'PaiementParCarteScreen',
                                      {
                                        token_money_in:
                                          retour_api?.token_paiement,
                                        arrivee_pipe_invest: true,
                                        id_transaction:
                                          retour_api?.id_transaction,
                                        id_souscription:
                                          props.route?.params
                                            ?.id_souscription_3_4 ??
                                          defaultProps.id_souscription_3_4,
                                      }
                                    );
                                  }
                                } else {
                                  if (
                                    maxDifference(
                                      fetchSouscriptionData?.montant_souscrit_euros,
                                      conversion_solde_wallet(
                                        fetchSouscriptionData
                                          ?.client_de_la_souscription
                                          ?.solde_wallet_client
                                      )
                                    ) === 0
                                  ) {
                                    const retour_api_paiement = (
                                      await baseXanoPaiementPATCH.mutateAsync({
                                        id_sous:
                                          props.route?.params
                                            ?.id_souscription_3_4 ??
                                          defaultProps.id_souscription_3_4,
                                      })
                                    )?.json;
                                    if (
                                      !retour_api_paiement?.succes_transaction
                                    ) {
                                      setMessage_erreur(true);
                                    }
                                    if (
                                      retour_api_paiement?.succes_transaction
                                    ) {
                                      if (navigation.canGoBack()) {
                                        navigation.popToTop();
                                      }
                                      navigation.replace(
                                        'ValidationPaiementScreen',
                                        {
                                          id_souscription:
                                            props.route?.params
                                              ?.id_souscription_3_4 ??
                                            defaultProps.id_souscription_3_4,
                                        }
                                      );
                                    }
                                  } else {
                                    navigation.navigate(
                                      'ReglementMixteScreen',
                                      {
                                        id_souscription_3_4:
                                          props.route?.params
                                            ?.id_souscription_3_4 ??
                                          defaultProps.id_souscription_3_4,
                                      }
                                    );
                                  }
                                }

                                setAffichage_chargement(false);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)[
                            'bouton_fond_blanc'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)[
                                'bouton_fond_blanc'
                              ].style,
                              {
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: null,
                                },
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 22 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 30,
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Investir'}
                        />
                      </LinearGradient>
                    )}
                  </>
                  {/* Message d'erreur */}
                  <>
                    {!message_erreur ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['message_erreur']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['message_erreur']
                            .style,
                          dimensions.width
                        )}
                      >
                        {
                          "Une erreur s'est produite lors du transfert de fonds. Veuillez réessayer."
                        }
                      </Text>
                    )}
                  </>
                </View>
              </View>
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <FooterDesktopBlock />
                )}
              </>
            </SimpleStyleScrollView>
          );
        }}
      </BaseXanoApi.FetchSousParIdGET>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(Souscription4Screen);
