import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  Link,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  SimpleStyleScrollView,
  Spacer,
  Surface,
  TextInput,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { arrivee_inscription: false, email: null, mdp: null };

const ConnexionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_atterrissage_mobile, setAffichage_atterrissage_mobile] =
    React.useState(false);
  const [emailInputValue, setEmailInputValue] = React.useState(
    props.route?.params?.email ?? defaultProps.email
  );
  const [message_erreur, setMessage_erreur] = React.useState('');
  const [passwordInputValue, setPasswordInputValue] = React.useState(null);
  // Récupère un paramètre dans l'url
  const url_param_test = () => {
    // Vérifie si le code est exécuté sur un navigateur web
    const isWeb =
      typeof window !== 'undefined' &&
      typeof navigator !== 'undefined' &&
      !/android|iphone|ipad|ipod/i.test(navigator.userAgent);

    if (!isWeb) {
      console.log('La fonction est désactivée sur iOS ou Android.');
      return null;
    }

    // Extraction de l'URL
    const urlParams = new URLSearchParams(window.location.search);
    const raizersParam = urlParams.get('raizers');

    if (raizersParam) {
      console.log(`Paramètre "raizers" trouvé : ${raizersParam}`);
    } else {
      console.log('Le paramètre "raizers" est introuvable dans l\'URL.');
    }

    return raizersParam;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        url_param_test();
        if (
          props.route?.params?.arrivee_inscription ??
          defaultProps.arrivee_inscription
        ) {
          setAffichage_atterrissage_mobile(false);
        }
        if (
          !(Platform.OS === 'web') &&
          !(
            props.route?.params?.arrivee_inscription ??
            defaultProps.arrivee_inscription
          )
        ) {
          setAffichage_atterrissage_mobile(true);
        }
        setEmailInputValue(props.route?.params?.email ?? defaultProps.email);
        setPasswordInputValue(props.route?.params?.mdp ?? defaultProps.mdp);
        const retour_api = (await BaseXanoApi.majRacineWebkitGET(Constants))
          ?.json;
        setGlobalVariableValue({
          key: 'webkit_racine',
          value: retour_api?.lien_webkit,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          justifyContent: {
            minWidth: Breakpoints.BigScreen,
            value: 'space-between',
          },
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      {/* Atterrissage mobile web */}
      <>
        {!(
          !affichage_atterrissage_mobile &&
          !(dimensions.width >= Breakpoints.Laptop)
        ) ? null : (
          <LinearGradient
            endY={100}
            startX={0}
            startY={0}
            color1={palettes.App['Flolend 4']}
            color2={palettes.App['Flolend 2']}
            endX={25}
            style={StyleSheet.applyWidth(
              { height: '100%', width: '100%' },
              dimensions.width
            )}
          >
            <SimpleStyleKeyboardAwareScrollView
              enableAutomaticScroll={false}
              enableOnAndroid={false}
              enableResetScrollToCoords={false}
              keyboardShouldPersistTaps={'never'}
              viewIsInsideTabBar={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  flex: 1,
                  justifyContent: 'center',
                  paddingBottom: 48,
                  paddingLeft: 48,
                  paddingRight: 48,
                  paddingTop: 48,
                },
                dimensions.width
              )}
            >
              {/* Vue Flolend */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'contain'}
                  source={imageSource(Images['LogoTexteBlancMilieu'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 50, width: 200 }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              <Spacer left={8} right={8} bottom={12} top={12} />
              {/* Message d'erreur */}
              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App.Internal_Hot_Red,
                        fontFamily: 'Montserrat_400Regular',
                        fontSize: { minWidth: Breakpoints.Tablet, value: 16 },
                        marginBottom: 5,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {message_erreur}
                </Text>
              </View>

              <View>
                {/* Email */}
                <View>
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_500Medium',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {'Email'}
                  </Text>
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 2,
                        borderColor: palettes.Brand['Light Inverse'],
                        flexDirection: 'row',
                        marginTop: 8,
                        paddingBottom: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors.background.brand}
                      name={'MaterialIcons/alternate-email'}
                      size={20}
                      style={StyleSheet.applyWidth(
                        { height: 20, width: 20 },
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginLeft: 4 },
                        dimensions.width
                      )}
                    >
                      {/* email_input */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newEmailInputValue => {
                          try {
                            setEmailInputValue(newEmailInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        keyboardType={'email-address'}
                        placeholder={
                          'Votre adresse mail' ?? 'JaneDoh@email.com'
                        }
                        placeholderTextColor={palettes.Brand['Light Inverse']}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Montserrat_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                        value={emailInputValue}
                      />
                    </View>
                  </View>
                </View>
                <Spacer left={8} right={8} bottom={12} top={12} />
                {/* Password */}
                <View>
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_500Medium',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {'Mot de passe'}
                  </Text>
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 2,
                        borderColor: palettes.Brand['Light Inverse'],
                        flexDirection: 'row',
                        marginTop: 8,
                        paddingBottom: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors.background.brand}
                      name={'MaterialIcons/lock-outline'}
                      size={20}
                      style={StyleSheet.applyWidth(
                        { height: 20, width: 20 },
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginLeft: 4 },
                        dimensions.width
                      )}
                    >
                      {/* password_input */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newPasswordInputValue => {
                          try {
                            setPasswordInputValue(newPasswordInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        keyboardType={'default'}
                        placeholder={'Votre mot de passe' ?? 'MySecretPassword'}
                        placeholderTextColor={palettes.Brand['Light Inverse']}
                        secureTextEntry={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Montserrat_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                        value={passwordInputValue}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <Spacer left={8} right={8} bottom={32} top={32} />
              {/* Encart entrées */}
              <View>
                {/* bouton co */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const retour_API = (
                          await BaseXanoApi.loginClientPOST(Constants, {
                            mail_du_client: emailInputValue,
                            mdp: passwordInputValue,
                          })
                        )?.json;
                        if (retour_API?.compte_sup && Platform.OS === 'ios') {
                          setMessage_erreur(
                            'Ce compte est en cours de suppression. Veuillez contacter le service client.'
                          );
                        }
                        if (retour_API?.compte_sup && Platform.OS === 'ios') {
                          return;
                        }
                        const authToken = retour_API?.authToken;
                        const message = retour_API?.message;
                        if (message === 'Invalid email format.') {
                          setMessage_erreur('Adresse mail invalide');
                        } else {
                          setMessage_erreur(message);
                        }

                        if (!authToken) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'auth_header',
                          value: 'Bearer ' + authToken,
                        });
                        const statut_compte = retour_API?.statut_compte;
                        if (statut_compte) {
                          const verif_email = retour_API?.verif_email;
                          if (verif_email) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('BottomTabNavigator', {
                              screen: 'EventailProjetsScreen',
                            });
                          } else {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('Inscription3Screen', {
                              arrivee_inscription2: false,
                              arrivee_connexion: true,
                            });
                          }
                        } else {
                          const crea_cgp = retour_API?.arrivee_cgp;
                          if (crea_cgp) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace(
                              'DemarrageClientIntermedieScreen'
                            );
                          } else {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('Inscription2Screen');
                          }
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderColor: palettes.App['Flolend 4'],
                      borderRadius: 24,
                      borderWidth: 2,
                      color: theme.colors.background.brand,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Tablet, value: 20 },
                      ],
                      paddingBottom: 16,
                      paddingTop: 16,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={"C'est parti !"}
                />
                <Spacer left={8} right={8} bottom={12} top={12} />
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Link inscription */}
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('Inscription1Screen', {
                          e_mail_du_client: emailInputValue,
                          mot_de_passe_du_client: passwordInputValue,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                        ],
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={"Vous n'avez pas de compte ? Inscrivez-vous !"}
                  />
                </View>
                {/* Vue mot de passe oublié */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Link mdp oublie */}
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('MotDePasseOublieScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                        ],
                        marginTop: 20,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Mot de passe oublié ?'}
                  />
                </View>
              </View>
            </SimpleStyleKeyboardAwareScrollView>
          </LinearGradient>
        )}
      </>
      {/* Atterrissage ordinateurs */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['Custom Color_22'],
                },
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                height: [
                  { minWidth: Breakpoints.BigScreen, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-around',
                },
              },
              dimensions.width
            )}
          >
            {/* Vue vidéo */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      height: [
                        { minWidth: Breakpoints.Laptop, value: 475 },
                        { minWidth: Breakpoints.Desktop, value: 500 },
                        { minWidth: Breakpoints.BigScreen, value: 550 },
                      ],
                      width: { minWidth: Breakpoints.Laptop, value: '48%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Fetch video */}
                  <BaseXanoApi.FetchArrierePlanDemarrageGET>
                    {({
                      loading,
                      error,
                      data,
                      refetchArrierePlanDemarrage,
                    }) => {
                      const fetchVideoData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Surface
                            {...GlobalStyles.SurfaceStyles(theme)['Surface']
                              .props}
                            elevation={3}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .style,
                                {
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 24,
                                  },
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                  overflow: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'hidden',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <VideoPlayer
                              posterResizeMode={'cover'}
                              rate={1}
                              resizeMode={'cover'}
                              usePoster={false}
                              volume={0.5}
                              {...GlobalStyles.VideoPlayerStyles(theme)['Video']
                                .props}
                              isLooping={true}
                              isMuted={true}
                              shouldPlay={true}
                              source={imageSource(`${fetchVideoData}`)}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.VideoPlayerStyles(theme)['Video']
                                    .style,
                                  {
                                    height: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                    width: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                              useNativeControls={false}
                            />
                          </Surface>
                        </View>
                      );
                    }}
                  </BaseXanoApi.FetchArrierePlanDemarrageGET>
                </View>
              )}
            </>
            {/* Vue carte */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.flolend_gris_nuance_encart,
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                  height: [
                    { minWidth: Breakpoints.Laptop, value: 475 },
                    { minWidth: Breakpoints.Desktop, value: 500 },
                    { minWidth: Breakpoints.BigScreen, value: 550 },
                  ],
                  maxWidth: { minWidth: Breakpoints.Laptop, value: '48%' },
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: 48 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 48 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 48 },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 48 },
                },
                dimensions.width
              )}
            >
              {/* Vue logo */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: 40 },
                    marginBottom: { minWidth: Breakpoints.Laptop, value: '5%' },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  },
                  dimensions.width
                )}
              >
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'center'}
                  source={imageSource(Images['LogoTexteBlancMilieu'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* Vue encarts entrées */}
              <View>
                {/* Email */}
                <View
                  style={StyleSheet.applyWidth(
                    { gap: { minWidth: Breakpoints.Laptop, value: 5 } },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.background.brand,
                          },
                        ],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Montserrat_500Medium',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Montserrat_600SemiBold',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        textTransform: {
                          minWidth: Breakpoints.Laptop,
                          value: 'uppercase',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {'Email'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App['Custom Color_24'],
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 24,
                        },
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* email_input */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newEmailInputValue => {
                        try {
                          setEmailInputValue(newEmailInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      keyboardType={'email-address'}
                      placeholder={'Votre adresse mail' ?? 'JaneDoh@email.com'}
                      placeholderTextColor={palettes.App['Custom Color_25']}
                      style={StyleSheet.applyWidth(
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors.background.brand,
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors.background.brand,
                            },
                          ],
                          fontFamily: 'Montserrat_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Tablet, value: 20 },
                            { minWidth: Breakpoints.Laptop, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 20 },
                            { minWidth: Breakpoints.BigScreen, value: 24 },
                          ],
                        },
                        dimensions.width
                      )}
                      value={emailInputValue}
                    />
                  </View>
                </View>
                {/* Password */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                      marginTop: { minWidth: Breakpoints.Laptop, value: '5%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.background.brand,
                          },
                        ],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Montserrat_500Medium',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Montserrat_600SemiBold',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        textTransform: {
                          minWidth: Breakpoints.Laptop,
                          value: 'uppercase',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {'Mot de passe'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App['Custom Color_24'],
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 24,
                        },
                        height: { minWidth: Breakpoints.Laptop, value: 50 },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* password_input */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newPasswordInputValue => {
                        try {
                          setPasswordInputValue(newPasswordInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      keyboardType={'default'}
                      placeholder={'Votre mot de passe' ?? 'MySecretPassword'}
                      placeholderTextColor={palettes.App['Custom Color_25']}
                      secureTextEntry={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors.background.brand,
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors.background.brand,
                            },
                          ],
                          fontFamily: 'Montserrat_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Tablet, value: 20 },
                            { minWidth: Breakpoints.Laptop, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 20 },
                            { minWidth: Breakpoints.BigScreen, value: 24 },
                          ],
                        },
                        dimensions.width
                      )}
                      value={passwordInputValue}
                    />
                  </View>
                </View>
              </View>
              {/* Message d'erreur */}
              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App.Internal_Hot_Red,
                        fontFamily: 'Montserrat_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        marginBottom: 5,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {message_erreur}
                </Text>
              </View>
              {/* Encart entrées */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: { minWidth: Breakpoints.Laptop, value: '2%' } },
                  dimensions.width
                )}
              >
                {/* bouton co ordi */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const retour_API = (
                          await BaseXanoApi.loginClientPOST(Constants, {
                            mail_du_client: emailInputValue,
                            mdp: passwordInputValue,
                          })
                        )?.json;
                        const authToken = retour_API?.authToken;
                        const message = retour_API?.message;
                        if (message === 'Invalid email format.') {
                          setMessage_erreur('Adresse mail invalide');
                        } else {
                          setMessage_erreur(message);
                        }

                        if (!authToken) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'auth_header',
                          value: 'Bearer ' + authToken,
                        });
                        const statut_compte = retour_API?.statut_compte;
                        if (statut_compte) {
                          const verif_email = retour_API?.verif_email;
                          if (verif_email) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('BottomTabNavigator', {
                              screen: 'EventailProjetsScreen',
                            });
                          } else {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('Inscription3Screen', {
                              arrivee_inscription2: false,
                              arrivee_connexion: true,
                            });
                          }
                        } else {
                          const crea_cgp = retour_API?.arrivee_cgp;
                          if (crea_cgp) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace(
                              'DemarrageClientIntermedieScreen'
                            );
                          } else {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('Inscription2Screen');
                          }
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderColor: palettes.App['Flolend 4'],
                      borderRadius: 24,
                      borderWidth: 2,
                      color: theme.colors.background.brand,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Tablet, value: 20 },
                        { minWidth: Breakpoints.Desktop, value: 24 },
                        { minWidth: Breakpoints.BigScreen, value: 28 },
                      ],
                      paddingBottom: 16,
                      paddingTop: 16,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Connexion'}
                />
                <Spacer left={8} right={8} bottom={12} top={12} />
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'center' },
                    dimensions.width
                  )}
                >
                  {/* bouton inscription ordi */}
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('Inscription1Screen', {
                          e_mail_du_client: emailInputValue,
                          mot_de_passe_du_client: passwordInputValue,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.background.brand,
                          },
                        ],
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={"Vous n'avez pas de compte ? Inscrivez-vous !"}
                  />
                </View>
                {/* Vue mot de passe oublié */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'center' },
                    dimensions.width
                  )}
                >
                  {/* lien mdp oublie ordi */}
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('MotDePasseOublieScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.background.brand,
                          },
                        ],
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        marginTop: 20,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Mot de passe oublié ?'}
                  />
                </View>
              </View>
            </View>
          </SimpleStyleScrollView>
        )}
      </>
      {/* Atterrissage mobile (iOS & Android) */}
      <>
        {!(
          affichage_atterrissage_mobile &&
          !(dimensions.width >= Breakpoints.Laptop)
        ) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                flex: 1,
                height: dimensions.height,
                justifyContent: 'flex-end',
              },
              dimensions.width
            )}
          >
            {/* Fetch doc gen */}
            <BaseXanoApi.FetchArrierePlanDemarrageGET>
              {({ loading, error, data, refetchArrierePlanDemarrage }) => {
                const fetchDocGenData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    <>
                      {dimensions.width >= Breakpoints.Laptop ? null : (
                        <VideoPlayer
                          posterResizeMode={'cover'}
                          rate={1}
                          resizeMode={'cover'}
                          usePoster={false}
                          volume={0.5}
                          {...GlobalStyles.VideoPlayerStyles(theme)['Video']
                            .props}
                          isLooping={true}
                          isMuted={true}
                          shouldPlay={true}
                          source={imageSource(`${fetchDocGenData}`)}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.VideoPlayerStyles(theme)['Video']
                                .style,
                              {
                                height: '100%',
                                position: 'absolute',
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                          useNativeControls={false}
                        />
                      )}
                    </>
                    {/* Vue boutons */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: '20%',
                          },
                          paddingLeft: 30,
                          paddingRight: 30,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Cadre image */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          resizeMode={'contain'}
                          source={imageSource(Images['LogoTexteBlancMilieu'])}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { height: 75, width: 200 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* message_prelancement */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Montserrat_500Medium',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                            marginBottom: 35,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Bientôt disponible !'}
                      </Text>
                      {/* Encart entrées */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                            flexDirection: {
                              minWidth: Breakpoints.Tablet,
                              value: 'row',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Tablet,
                              value: 'space-around',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Spacer 3 */}
                        <Spacer bottom={0} left={0} right={0} top={0} />
                        <Spacer bottom={15} left={0} right={0} top={15} />
                        {/* Spacer 2 */}
                        <Spacer bottom={15} left={0} right={0} top={15} />
                      </View>
                    </View>
                  </>
                );
              }}
            </BaseXanoApi.FetchArrierePlanDemarrageGET>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ConnexionScreen);
