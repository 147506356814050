import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_souscription_3_4: 166 };

const ReglementMixteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_chargement, setAffichage_chargement] = React.useState(false);
  const [bouton, setBouton] = React.useState(false);
  const [select_CB, setSelect_CB] = React.useState(false);
  const [select_compte, setSelect_compte] = React.useState(false);
  // Convertit une somme en euros en une somme en centimes d'euros
  const conversion_centimes = montant_euros => {
    const centimes = montant_euros * 100;
    return centimes;
  };

  // Calcule la différence entre 2 paramètres et renvoie le maximum entre 0 et la différence calculée.
  const maxDifference = (param1, param2) => {
    // Calcul de la différence entre les deux paramètres
    let difference = param1 - param2;

    // Renvoie le maximum entre 0 et la différence calculée
    return Math.max(0, difference);
  };
  const baseXanoPaiement$tokenPaiementPOST =
    BaseXanoApi.usePaiement$tokenPaiementPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Fetch souscription */}
      <BaseXanoApi.FetchSousParIdGET
        id_souscription={
          props.route?.params?.id_souscription_3_4 ??
          defaultProps.id_souscription_3_4
        }
      >
        {({ loading, error, data, refetchSousParId }) => {
          const fetchSouscriptionData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Navigation mobile */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'center',
                        bottom: 20,
                        position: 'absolute',
                        width: '80%',
                        zIndex: 99,
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {affichage_chargement ? null : (
                        <LinearGradient
                          endX={100}
                          endY={100}
                          startX={0}
                          startY={0}
                          {...GlobalStyles.LinearGradientStyles(theme)[
                            'gradient_bouton'
                          ].props}
                          color1={
                            (bouton
                              ? palettes.App['Flolend 2']
                              : palettes.App.Internal_Light_Gray) ??
                            palettes.App['Flolend 2']
                          }
                          color2={
                            (bouton
                              ? palettes.App['Flolend 4']
                              : palettes.App.Internal_Light_Gray) ??
                            palettes.App['Flolend 4']
                          }
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.LinearGradientStyles(theme)[
                                'gradient_bouton'
                              ].style,
                              {
                                alignSelf: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                flex: [
                                  { minWidth: Breakpoints.Laptop, value: null },
                                  { minWidth: Breakpoints.Mobile, value: null },
                                ],
                                height: [
                                  { minWidth: Breakpoints.Laptop, value: 60 },
                                  { minWidth: Breakpoints.Desktop, value: 65 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 70,
                                  },
                                ],
                                marginBottom: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                                marginTop: [
                                  { minWidth: Breakpoints.Laptop, value: '2%' },
                                  { minWidth: Breakpoints.Mobile, value: null },
                                ],
                                overflow: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'hidden',
                                },
                                width: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '50%',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '75%',
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* Bouton continuer actif */}
                          <Button
                            accessible={true}
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  if (!bouton) {
                                    return;
                                  }
                                  setAffichage_chargement(true);
                                  if (select_CB) {
                                    const retour_api = (
                                      await baseXanoPaiement$tokenPaiementPOST.mutateAsync(
                                        {
                                          montant: maxDifference(
                                            fetchSouscriptionData?.montant_souscrit_euros *
                                              100,
                                            fetchSouscriptionData
                                              ?.client_de_la_souscription
                                              ?.solde_wallet_client
                                          ),
                                        }
                                      )
                                    )?.json;
                                    if (retour_api?.success) {
                                      if (navigation.canGoBack()) {
                                        navigation.popToTop();
                                      }
                                      navigation.replace(
                                        'PaiementParCarteScreen',
                                        {
                                          token_money_in:
                                            retour_api?.token_paiement,
                                          arrivee_pipe_invest: true,
                                          id_transaction:
                                            retour_api?.id_transaction,
                                          id_souscription:
                                            props.route?.params
                                              ?.id_souscription_3_4 ??
                                            defaultProps.id_souscription_3_4,
                                        }
                                      );
                                    }
                                  } else {
                                    navigation.navigate('IBANClientScreen');
                                  }

                                  setAffichage_chargement(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)[
                              'bouton_fond_blanc'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)[
                                  'bouton_fond_blanc'
                                ].style,
                                {
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: null,
                                  },
                                  fontSize: [
                                    { minWidth: Breakpoints.Laptop, value: 22 },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 30,
                                    },
                                  ],
                                  height: 45,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Continuer'}
                          />
                        </LinearGradient>
                      )}
                    </>
                    {/* Chargement en cours 2 */}
                    <>
                      {!affichage_chargement ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              gap: 10,
                              marginTop: '5%',
                              paddingLeft: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            {...GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].props}
                            color={palettes.App['Flolend 4']}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].style,
                              dimensions.width
                            )}
                          />
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['corps_contenu']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['corps_contenu']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Chargement en cours, veuillez patienter.'}
                          </Text>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              <SimpleStyleScrollView
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                bounces={false}
                scrollEnabled={true}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.BigScreen, value: '100%' },
                    justifyContent: [
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'space-between',
                      },
                      { minWidth: Breakpoints.Laptop, value: 'space-between' },
                    ],
                    paddingTop: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: Constants['hauteur_navbar_laptop'],
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: Constants['hauteur_navbar_desktop'],
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: Constants['hauteur_navbar_big_screen'],
                      },
                    ],
                    width: { minWidth: Breakpoints.BigScreen, value: '100%' },
                  },
                  dimensions.width
                )}
              >
                {/* Header */}
                <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingLeft: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Back btn */}
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              height: 44,
                              justifyContent: 'center',
                              width: 44,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={palettes.App.gris_fonce_flolend}
                            name={'AntDesign/arrowleft'}
                            size={18}
                          />
                        </View>
                      </Touchable>
                      {/* Screen Heading */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['screen_heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['screen_heading']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Règlement avec mon livret Flolend'}
                      </Text>
                    </View>
                  )}
                </>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.flolend_gris_nuance_encart,
                      },
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      marginLeft: [
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                        { minWidth: Breakpoints.Laptop, value: '20%' },
                      ],
                      marginRight: [
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                        { minWidth: Breakpoints.Laptop, value: '20%' },
                      ],
                      marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                      padding: { minWidth: Breakpoints.Laptop, value: 30 },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { width: { minWidth: Breakpoints.Laptop, value: '80%' } },
                      dimensions.width
                    )}
                  >
                    {/* Principe */}
                    <View>
                      {/* Heading */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['explications']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['explications'].style,
                          dimensions.width
                        )}
                      >
                        {
                          "Votre livret manque de fonds pour couvrir la souscription. Vous pouvez compléter son solde selon le mode de paiement que vous sélectionnerez ci-dessous. Les versements par carte bancaire sont disponibles jusqu'à "
                        }
                        {format_nombre(Constants['max_sous_cb'], 0)}
                        {' €.'}
                      </Text>
                    </View>
                    {/* Votre souscription */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: '2%' },
                        dimensions.width
                      )}
                    >
                      {/* Section Header */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Heading */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['titre1_generique']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['titre1_generique']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Votre souscription'}
                        </Text>
                      </View>
                      {/* Content */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderColor: palettes.App['Flolend 1'],
                            borderRadius: 12,
                            borderWidth: 1,
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'montants_options_souscription'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              'montants_options_souscription'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {'Montant de la souscription : '}
                          {format_nombre(
                            fetchSouscriptionData?.montant_souscrit_euros,
                            2
                          )}
                          {' €\nSolde de mon compte : '}
                          {format_nombre(
                            conversion_solde_wallet(
                              fetchSouscriptionData?.client_de_la_souscription
                                ?.solde_wallet_client
                            ),
                            2
                          )}
                          {' €\n--------\nMontant restant à verser : '}
                          {format_nombre(
                            maxDifference(
                              fetchSouscriptionData?.montant_souscrit_euros,
                              conversion_solde_wallet(
                                fetchSouscriptionData?.client_de_la_souscription
                                  ?.solde_wallet_client
                              )
                            ),
                            2
                          )}
                          {' €'}
                        </Text>
                      </View>
                    </View>
                    {/* Sélection du mode de paiement */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: '5%' },
                        dimensions.width
                      )}
                    >
                      {/* Section Header 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Heading */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['titre1_generique']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['titre1_generique']
                                .style,
                              { textAlign: 'left' }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'Règlement du montant restant à verser sur la souscription'
                          }
                        </Text>
                      </View>
                      {/* Carte bancaire */}
                      <Touchable
                        onPress={() => {
                          try {
                            setSelect_compte(true);
                            setSelect_CB(false);
                            setBouton(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* Encart */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              borderColor: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: palettes.App.Icon_Dark_Gray,
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: select_compte
                                    ? palettes.App['Flolend 4']
                                    : palettes.App.Icon_Dark_Gray,
                                },
                              ],
                              borderRadius: 12,
                              borderWidth: [
                                { minWidth: Breakpoints.Mobile, value: 1 },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: select_compte ? 2 : 1,
                                },
                              ],
                              justifyContent: 'center',
                              minHeight: 100,
                              paddingBottom: '1%',
                              paddingLeft: '2%',
                              paddingRight: '2%',
                              paddingTop: '1%',
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '75%' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_select_option_sous'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_select_option_sous'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Virement bancaire'}
                              </Text>

                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'montants_options_souscription'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'montants_options_souscription'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {
                                  'Réceptionnez les fonds sur votre livret puis finalisez votre souscription'
                                }
                              </Text>
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', width: '25%' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={
                                  (select_compte
                                    ? palettes.App['Flolend 4']
                                    : palettes.App.Icon_Dark_Gray) ??
                                  palettes.App.Icon_Dark_Gray
                                }
                                name={'MaterialCommunityIcons/bank-transfer'}
                                size={
                                  (dimensions.width >= Breakpoints.Laptop
                                    ? dimensions.width >= Breakpoints.Desktop
                                      ? dimensions.width >=
                                        Breakpoints.BigScreen
                                        ? 80
                                        : 70
                                      : 60
                                    : 40) ?? 40
                                }
                              />
                            </View>
                          </View>
                        </View>
                      </Touchable>
                      <>
                        {!(
                          maxDifference(
                            fetchSouscriptionData?.montant_souscrit_euros,
                            conversion_solde_wallet(
                              fetchSouscriptionData?.client_de_la_souscription
                                ?.solde_wallet_client
                            )
                          ) <= Constants['max_sous_cb']
                        ) ? null : (
                          <Spacer bottom={8} left={8} right={8} top={8} />
                        )}
                      </>
                      {/* Touchable CB */}
                      <>
                        {!(
                          maxDifference(
                            fetchSouscriptionData?.montant_souscrit_euros,
                            conversion_solde_wallet(
                              fetchSouscriptionData?.client_de_la_souscription
                                ?.solde_wallet_client
                            )
                          ) <= Constants['max_sous_cb']
                        ) ? null : (
                          <Touchable
                            onPress={() => {
                              try {
                                setSelect_compte(false);
                                setSelect_CB(true);
                                setBouton(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* Encart */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: palettes.App.Icon_Dark_Gray,
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: select_CB
                                        ? palettes.App['Flolend 4']
                                        : palettes.App.Icon_Dark_Gray,
                                    },
                                  ],
                                  borderRadius: 12,
                                  borderWidth: [
                                    { minWidth: Breakpoints.Mobile, value: 1 },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: select_CB ? 2 : 1,
                                    },
                                  ],
                                  justifyContent: 'center',
                                  minHeight: 100,
                                  paddingBottom: '1%',
                                  paddingLeft: '2%',
                                  paddingRight: '2%',
                                  paddingTop: '1%',
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '75%' },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'titre_select_option_sous'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'titre_select_option_sous'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Carte bancaire'}
                                  </Text>

                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'montants_options_souscription'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'montants_options_souscription'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Fonds virés immédiatement vers le projet'}
                                  </Text>
                                </View>
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'center', width: '25%' },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    color={
                                      (select_CB
                                        ? palettes.App['Flolend 4']
                                        : palettes.App.Icon_Dark_Gray) ??
                                      palettes.App.Icon_Dark_Gray
                                    }
                                    name={'AntDesign/creditcard'}
                                    size={
                                      (dimensions.width >= Breakpoints.Laptop
                                        ? dimensions.width >=
                                          Breakpoints.Desktop
                                          ? dimensions.width >=
                                            Breakpoints.BigScreen
                                            ? 80
                                            : 70
                                          : 60
                                        : 40) ?? 40
                                    }
                                  />
                                </View>
                              </View>
                            </View>
                          </Touchable>
                        )}
                      </>
                    </View>
                    {/* Navigation ordi */}
                    <>
                      {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                        <View>
                          <>
                            {affichage_chargement ? null : (
                              <LinearGradient
                                endX={100}
                                endY={100}
                                startX={0}
                                startY={0}
                                {...GlobalStyles.LinearGradientStyles(theme)[
                                  'gradient_bouton'
                                ].props}
                                color1={
                                  (bouton
                                    ? palettes.App['Flolend 2']
                                    : palettes.App.Internal_Light_Gray) ??
                                  palettes.App['Flolend 2']
                                }
                                color2={
                                  (bouton
                                    ? palettes.App['Flolend 4']
                                    : palettes.App.Internal_Light_Gray) ??
                                  palettes.App['Flolend 4']
                                }
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinearGradientStyles(theme)[
                                      'gradient_bouton'
                                    ].style,
                                    {
                                      alignSelf: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'center',
                                      },
                                      borderRadius: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 15,
                                      },
                                      flex: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: null,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: null,
                                        },
                                      ],
                                      height: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 60,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: null,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 65,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 70,
                                        },
                                      ],
                                      marginBottom: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: '2%',
                                        },
                                      ],
                                      marginTop: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: '2%',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: '5%',
                                        },
                                      ],
                                      overflow: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'hidden',
                                      },
                                      width: [
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: '50%',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: '75%',
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* Bouton continuer actif */}
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        if (!bouton) {
                                          return;
                                        }
                                        setAffichage_chargement(true);
                                        if (select_CB) {
                                          const retour_api = (
                                            await baseXanoPaiement$tokenPaiementPOST.mutateAsync(
                                              {
                                                montant: maxDifference(
                                                  fetchSouscriptionData?.montant_souscrit_euros *
                                                    100,
                                                  fetchSouscriptionData
                                                    ?.client_de_la_souscription
                                                    ?.solde_wallet_client
                                                ),
                                              }
                                            )
                                          )?.json;
                                          if (retour_api?.success) {
                                            if (navigation.canGoBack()) {
                                              navigation.popToTop();
                                            }
                                            navigation.replace(
                                              'PaiementParCarteScreen',
                                              {
                                                token_money_in:
                                                  retour_api?.token_paiement,
                                                arrivee_pipe_invest: true,
                                                id_transaction:
                                                  retour_api?.id_transaction,
                                                id_souscription:
                                                  props.route?.params
                                                    ?.id_souscription_3_4 ??
                                                  defaultProps.id_souscription_3_4,
                                              }
                                            );
                                          }
                                        } else {
                                          navigation.navigate(
                                            'IBANClientScreen'
                                          );
                                        }

                                        setAffichage_chargement(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton_fond_blanc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'bouton_fond_blanc'
                                      ].style,
                                      {
                                        borderRadius: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 15,
                                        },
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 22,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 30,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Continuer'}
                                />
                              </LinearGradient>
                            )}
                          </>
                          {/* Chargement en cours 2 */}
                          <>
                            {!affichage_chargement ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    gap: 10,
                                    marginTop: '5%',
                                    paddingLeft: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  {...GlobalStyles.ActivityIndicatorStyles(
                                    theme
                                  )['Activity Indicator'].props}
                                  color={palettes.App['Flolend 4']}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ActivityIndicatorStyles(theme)[
                                      'Activity Indicator'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'corps_contenu'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'corps_contenu'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Chargement en cours, veuillez patienter.'}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                </View>
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <FooterDesktopBlock />
                  )}
                </>
              </SimpleStyleScrollView>
            </>
          );
        }}
      </BaseXanoApi.FetchSousParIdGET>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(ReglementMixteScreen);
