import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const Categorisation1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activateur_bouton, setActivateur_bouton] = React.useState(false);
  const [question_1, setQuestion_1] = React.useState('');
  const [question_2, setQuestion_2] = React.useState('');
  const [question_3, setQuestion_3] = React.useState('');
  const [question_4, setQuestion_4] = React.useState('');
  const [question_5, setQuestion_5] = React.useState('');
  // Renvoie TRUE si une information manque.
  const verif_completude = (info1, info2, info3, info4, info5) => {
    return (
      info1 === '' ||
      info2 === '' ||
      info3 === '' ||
      info4 === '' ||
      info5 === ''
    );
  };
  const baseXanoCategorisationPATCH = BaseXanoApi.useCategorisationPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            marginLeft: [
              { minWidth: Breakpoints.Laptop, value: '0%' },
              { minWidth: Breakpoints.Tablet, value: '10%' },
            ],
            marginRight: [
              { minWidth: Breakpoints.Laptop, value: '0%' },
              { minWidth: Breakpoints.Tablet, value: '10%' },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Vue tous supports */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '15%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '15%' },
              ],
              marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
            },
            dimensions.width
          )}
        >
          {/* En-tête */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'column',
                marginTop: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
              },
              dimensions.width
            )}
          >
            {/* entete */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: { minWidth: Breakpoints.Laptop, value: '2%' },
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { width: '95%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                    dimensions.width
                  )}
                >
                  {'Votre profil investisseur'}
                </Text>
              </View>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('DossierInvestisseurScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <>
                        {dimensions.width >= Breakpoints.Laptop ? null : (
                          <Icon
                            color={palettes.App.gris_fonce_flolend}
                            name={'Ionicons/exit-outline'}
                            size={18}
                          />
                        )}
                      </>
                    </Touchable>
                  </View>
                )}
              </>
            </View>
          </View>
          {/* Description */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginBottom: 10,
                marginTop: 10,
                paddingLeft: { minWidth: Breakpoints.Laptop, value: 0 },
                paddingRight: { minWidth: Breakpoints.Laptop, value: 0 },
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['explications'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['explications'].style,
                dimensions.width
              )}
            >
              {
                "Ce questionnaire vise à déterminer votre profil d'investisseur (averti ou non-averti) au sens de la réglementation UE 2020/1503. Un investisseur non-averti recevra des recommandations dédiées pour l'aiguiller dans ses investissements."
              }
            </Text>
          </View>
          {/* Vue questions */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App.flolend_gris_nuance_encart,
                borderRadius: 24,
                marginLeft: { minWidth: Breakpoints.Laptop, value: '0%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '0%' },
                padding: 20,
              },
              dimensions.width
            )}
          >
            {/* Question 1 */}
            <View
              {...GlobalStyles.ViewStyles(theme)[
                'encart_question_profil_invest'
              ].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['encart_question_profil_invest']
                  .style,
                dimensions.width
              )}
            >
              {/* Q1 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['question_profil_invest']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['question_profil_invest']
                    .style,
                  dimensions.width
                )}
              >
                {
                  'Au cours de la dernière année fiscale, vos revenus personnels bruts étaient de plus de 60 000 €.'
                }
              </Text>

              <View>
                <RadioButtonGroup
                  onValueChange={newRadioButtonGroupValue => {
                    try {
                      const valueaKnu3Mi5 = newRadioButtonGroupValue;
                      setQuestion_1(valueaKnu3Mi5);
                      const retour = valueaKnu3Mi5;
                      const completude = verif_completude(
                        retour,
                        question_2,
                        question_3,
                        question_4,
                        question_5
                      );
                      setActivateur_bouton(!completude);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.RadioButtonGroupStyles(theme)[
                    'groupe_question_profil_invest'
                  ].props}
                  direction={'horizontal'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonGroupStyles(theme)[
                      'groupe_question_profil_invest'
                    ].style,
                    dimensions.width
                  )}
                  value={question_1}
                >
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Vrai'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Vrai'}
                  />
                  {/* Radio Button Row 2 */}
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Faux'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Faux'}
                  />
                </RadioButtonGroup>
              </View>
            </View>
            {/* Question 2 */}
            <View
              {...GlobalStyles.ViewStyles(theme)[
                'encart_question_profil_invest'
              ].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['encart_question_profil_invest']
                  .style,
                dimensions.width
              )}
            >
              {/* Q2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['question_profil_invest']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['question_profil_invest']
                    .style,
                  dimensions.width
                )}
              >
                {
                  "La valeur de votre portefeuille d'instruments financiers est supérieure à 100 000 €."
                }
              </Text>

              <View>
                <RadioButtonGroup
                  onValueChange={newRadioButtonGroupValue => {
                    try {
                      const valueZqx9Jy9i = newRadioButtonGroupValue;
                      setQuestion_2(valueZqx9Jy9i);
                      const retour = valueZqx9Jy9i;
                      const completude = verif_completude(
                        question_1,
                        retour,
                        question_3,
                        question_4,
                        question_5
                      );
                      setActivateur_bouton(!completude);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.RadioButtonGroupStyles(theme)[
                    'groupe_question_profil_invest'
                  ].props}
                  direction={'horizontal'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonGroupStyles(theme)[
                      'groupe_question_profil_invest'
                    ].style,
                    dimensions.width
                  )}
                  value={question_2}
                >
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Vrai'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Vrai'}
                  />
                  {/* Radio Button Row 2 */}
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Faux'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Faux'}
                  />
                </RadioButtonGroup>
              </View>
            </View>
            {/* Question 3 */}
            <View
              {...GlobalStyles.ViewStyles(theme)[
                'encart_question_profil_invest'
              ].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['encart_question_profil_invest']
                  .style,
                dimensions.width
              )}
            >
              {/* Q3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['question_profil_invest']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['question_profil_invest']
                    .style,
                  dimensions.width
                )}
              >
                {
                  'Vous avez exercé ou exercez depuis au moins un an une activité professionnelle dans le secteur financier requérant une connaissance des services envisagés.'
                }
              </Text>

              <View>
                <RadioButtonGroup
                  onValueChange={newRadioButtonGroupValue => {
                    try {
                      const valueSI4a5TJ0 = newRadioButtonGroupValue;
                      setQuestion_3(valueSI4a5TJ0);
                      const retour = valueSI4a5TJ0;
                      const completude = verif_completude(
                        question_1,
                        question_2,
                        retour,
                        question_4,
                        question_5
                      );
                      setActivateur_bouton(!completude);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.RadioButtonGroupStyles(theme)[
                    'groupe_question_profil_invest'
                  ].props}
                  direction={'horizontal'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonGroupStyles(theme)[
                      'groupe_question_profil_invest'
                    ].style,
                    dimensions.width
                  )}
                  value={question_3}
                >
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Vrai'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Vrai'}
                  />
                  {/* Radio Button Row 2 */}
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Faux'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Faux'}
                  />
                </RadioButtonGroup>
              </View>
            </View>
            {/* Question 4 */}
            <View
              {...GlobalStyles.ViewStyles(theme)[
                'encart_question_profil_invest'
              ].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['encart_question_profil_invest']
                  .style,
                dimensions.width
              )}
            >
              {/* Q4 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['question_profil_invest']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['question_profil_invest']
                    .style,
                  dimensions.width
                )}
              >
                {
                  'Vous avez occupé une fonction de cadre supérieur pendant au moins un an dans une société dont le chiffre d’affaires est supérieur à deux millions d’euros.'
                }
              </Text>

              <View>
                <RadioButtonGroup
                  onValueChange={newRadioButtonGroupValue => {
                    try {
                      const valueBlItcLxc = newRadioButtonGroupValue;
                      setQuestion_4(valueBlItcLxc);
                      const retour = valueBlItcLxc;
                      const completude = verif_completude(
                        question_1,
                        question_2,
                        question_3,
                        retour,
                        question_5
                      );
                      setActivateur_bouton(!completude);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.RadioButtonGroupStyles(theme)[
                    'groupe_question_profil_invest'
                  ].props}
                  direction={'horizontal'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonGroupStyles(theme)[
                      'groupe_question_profil_invest'
                    ].style,
                    dimensions.width
                  )}
                  value={question_4}
                >
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Vrai'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Vrai'}
                  />
                  {/* Radio Button Row 2 */}
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Faux'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Faux'}
                  />
                </RadioButtonGroup>
              </View>
            </View>
            {/* Question 5 */}
            <View
              {...GlobalStyles.ViewStyles(theme)[
                'encart_question_profil_invest'
              ].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['encart_question_profil_invest']
                  .style,
                dimensions.width
              )}
            >
              {/* Q5 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['question_profil_invest']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['question_profil_invest']
                    .style,
                  dimensions.width
                )}
              >
                {
                  'Au cours des douze derniers mois, vous avez effectué plus de 40 transactions sur les marchés de capitaux.'
                }
              </Text>

              <View>
                <RadioButtonGroup
                  onValueChange={newRadioButtonGroupValue => {
                    try {
                      const valuem5WUfvmj = newRadioButtonGroupValue;
                      setQuestion_5(valuem5WUfvmj);
                      const retour = valuem5WUfvmj;
                      const completude = verif_completude(
                        question_1,
                        question_2,
                        question_3,
                        question_4,
                        retour
                      );
                      setActivateur_bouton(!completude);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.RadioButtonGroupStyles(theme)[
                    'groupe_question_profil_invest'
                  ].props}
                  direction={'horizontal'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonGroupStyles(theme)[
                      'groupe_question_profil_invest'
                    ].style,
                    dimensions.width
                  )}
                  value={question_5}
                >
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Vrai'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Vrai'}
                  />
                  {/* Radio Button Row 2 */}
                  <RadioButtonRow
                    color={theme.colors.branding.primary}
                    unselectedColor={theme.colors.branding.primary}
                    {...GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].props}
                    label={'Faux'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.RadioButtonRowStyles(theme)[
                        'questionnaire_radio_button'
                      ].style,
                      dimensions.width
                    )}
                    value={'Faux'}
                  />
                </RadioButtonGroup>
              </View>
            </View>

            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                .props}
              color1={
                (activateur_bouton
                  ? palettes.App['Flolend 2']
                  : palettes.App.gris_fonce_flolend) ??
                palettes.App['Flolend 2']
              }
              color2={
                (activateur_bouton
                  ? palettes.App['Flolend 4']
                  : palettes.App.gris_fonce_flolend) ??
                palettes.App['Flolend 4']
              }
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                    .style,
                  {
                    alignSelf: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                    flex: { minWidth: Breakpoints.Laptop, value: null },
                    height: [
                      { minWidth: Breakpoints.Desktop, value: 65 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                      { minWidth: Breakpoints.Laptop, value: 60 },
                    ],
                    marginBottom: [
                      { minWidth: Breakpoints.Mobile, value: 30 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
                    width: [
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                      { minWidth: Breakpoints.Laptop, value: '75%' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* Bouton étape suivante */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      if (!activateur_bouton) {
                        return;
                      }
                      const retour_api = (
                        await baseXanoCategorisationPATCH.mutateAsync({
                          quest1: question_1,
                          quest2: question_2,
                          quest3: question_3,
                          quest4: question_4,
                          quest5: question_5,
                        })
                      )?.json;
                      navigation.navigate('Categorisation2Screen', {
                        categorie: retour_api?.categorie,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                  dimensions.width
                )}
                title={'Valider'}
              />
            </LinearGradient>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(Categorisation1Screen);
